@import "variables";
@import "theme-colors";

.circular-progress-bar {
  $size: 80px;
  $borderWidth: 5px;
  $animationTime: 1.1s;
  $border-color-default: var(--base-color);

  // Create how many steps
  $howManySteps: 100; //this needs to be even.

  .progress {
    width: $size;
    height: $size;
    line-height: $size;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: $borderWidth solid $border-color-default;
      position: absolute;
      top: 0;
      left: 0;
    }

    > span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    .progress-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: $borderWidth;
      border-style: solid;
      position: absolute;
      top: 0;
      border-color: $success-color;
    }

    .progress-left {
      left: 0;

      .progress-bar {
        left: 100%;
        border-top-right-radius: ($size/2);;
        border-bottom-right-radius: ($size/2);;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
        animation: loading-2 1.5s linear forwards 1.8s;
      }
    }

    .progress-right {
      right: 0;

      .progress-bar {
        left: -100%;
        border-top-left-radius: ($size/2);;
        border-bottom-left-radius: ($size/2);;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
      }
    }

    .progress-value {
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      align-items: center;
      justify-content: center;
    }
  }

  small {
    color: $default-secondary-color;
  }

  /*Progressbar Animations*/
  @for $i from 1 through $howManySteps {
    $stepName: ($i*(100 / $howManySteps));

    //animation only the left side if below 50%
    @if $i <= ($howManySteps/2) {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
          animation: loading-#{$i} $animationTime linear forwards;
        }

        .progress-left .progress-bar {
          animation: 0;
        }
      }
    }

    //animation only the right side if above 50%
    @if $i > ($howManySteps/2) {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
          animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
        }

        .progress-left .progress-bar {
          animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
        }
      }
    }
  }

  //animation
  @for $i from 1 through ($howManySteps/2) {
    $degrees: (180/($howManySteps/2));
    $degrees: ($degrees*$i);
    @keyframes loading-#{$i}{
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate($degrees);
        transform: rotate(#{$degrees}deg);
      }
    }
  }
}
